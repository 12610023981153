import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
// import { TbArrowBigRight } from "react-icons/tb"

export default function Home({ location }) {
  console.log("loc", location)

  return (
    <Layout pageTitle="Events">
      <section className="reading">
        <h1>Events</h1>
        <StaticImage className="screenshot" objectFit="contain" src="../images/kings-don-taiko.jpg" alt="King's Don Taiko" />
        <article>
          <p>
            Since moving to Kingston in 2011, Kyoko has performed mainly locally for the Live @ Your Library Jazz series, L.C.V.I., Frontenac Public School, Kingston Multicultural Arts Festival, St. George’s Cathedral Advent/Summer series, Kingston Home Grown Live, Studio 22, Tone Deaf series,
            Hiroshima Day, Skeleton Park Arts Festival, Music West series at St. Andrew’s By-The-Lake United Church, The TETT Centre Grand Opening (The Whole Shebang Project Kingston), Queen’s University, Building Community Through the Arts Speaker series, and a Fund-raising concert for Syrian
            refugees for Inter-Church Refugee Partnership and NOW Society.
          </p>
        </article>
      </section>
    </Layout>
  )
}
